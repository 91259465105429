import { ICellRendererParams } from "ag-grid-enterprise";
import { getIsCalculated } from "./../../../utils/getIsCalculated";
import { CSSProperties } from "react";

export const TextColorCellRenderer = (params: ICellRendererParams) => {
  const cellValue: string = params.valueFormatted ?? params.value?.toString();
  const cellStyle: CSSProperties = { color: "" };
  const isCalculated: boolean | null | undefined = getIsCalculated(params);
  const isEditable: boolean = params?.node?.data?.editable;

  if (typeof isCalculated === "boolean") {
    if (isCalculated === true) {
      cellStyle.color = "blue";
    }
    if (isCalculated === false && isEditable) {
      cellStyle.color = "orange";
    }
  }

  const textColorElement = <span style={cellStyle}>{cellValue}</span>;

  return textColorElement;
};
