import { Text, View } from "@react-pdf/renderer";
import {
  formatDate,
  formatText,
  makePercent,
  roundNumber,
} from "../formatPdfContent";
import { FinalAccountReport } from "../../../../interfaces/reports/FinalAccountReport";
import { styles } from "../styles";
import { PdfTemplate } from "../PdfTemplate";

interface FinalAccountPdfProps {
  reportData: FinalAccountReport | undefined;
}

export const FinalAccountPdf = ({ reportData }: FinalAccountPdfProps) => {
  const header = reportData?.header;
  const income = reportData?.income;
  const expenses = reportData?.expenses;
  const capitalCost = reportData?.capitalCost;
  const totalProjectExpenses = reportData?.totalProjectExpenses;
  const totalResult = reportData?.totalResult;
  const margins = reportData?.margins;
  const summary = reportData?.summary;

  const page1 = () => (
    <>
      <View style={styles.section}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableRowCol1}>Projekt</Text>
            <Text style={styles.tableRowCol2}>
              {formatText(header?.projectName)}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableRowCol1}>Projektnummer</Text>
            <Text style={styles.tableRowCol2}>
              {formatText(header?.projectId)}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableRowCol1}>Ansvarig projektledare</Text>
            <Text style={styles.tableRowCol2}>
              {formatText(header?.projectManager)}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableRowCol1}>Godkänd slutbesiktning</Text>
            <Text style={styles.tableRowCol2}>
              {formatDate(header?.dateOfFinalInspection)}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableRowCol1}>Slutredovisning</Text>
            <Text style={styles.tableRowCol2}>
              {formatDate(header?.dateOfFinalReport)}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableRowCol1}>Bokföringsdag</Text>
            <Text style={styles.tableRowCol2}>
              {formatDate(header?.dateOfAccounting)}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionHeader}>Intäkter</Text>

        <View style={styles.table}>
          {income?.accounts.map((account, index) => {
            return (
              <View style={styles.tableRow} key={index}>
                <Text style={styles.tableRowCol1}>{`${formatText(
                  account.name
                )} (${account.number})`}</Text>
                <Text style={styles.tableRowCol2}>
                  {roundNumber(account.value)}
                </Text>
              </View>
            );
          })}
          <View style={styles.tableSumRow}>
            <Text style={styles.tableRowCol1}>Totala projektintäkter</Text>
            <Text style={styles.tableRowCol2}>
              {roundNumber(income?.total)}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <View style={styles.section}>
          <Text style={styles.sectionHeader}>Kostnader</Text>
          <View style={styles.table}>
            {expenses?.accounts.map((account, index) => {
              return (
                <View style={styles.tableRow} key={index}>
                  <Text style={styles.tableRowCol1}>{`${formatText(
                    account.name
                  )} (${account.number})`}</Text>
                  <Text style={styles.tableRowCol2}>
                    {roundNumber(account.value)}
                  </Text>
                </View>
              );
            })}

            <View style={styles.tableSumRow}>
              <Text style={styles.tableRowCol1}>Summa kostnader</Text>
              <Text style={styles.tableRowCol2}>
                {roundNumber(expenses?.total)}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.section}>
          <View style={styles.table}>
            {capitalCost?.accounts.map((account, index) => {
              return (
                <View style={styles.tableRow} key={index}>
                  <Text style={styles.tableRowCol1}>
                    {formatText(account.name)}
                  </Text>
                  <Text style={styles.tableRowCol2}>
                    {roundNumber(account.value)}
                  </Text>
                </View>
              );
            })}

            <View style={styles.tableSumRow}>
              <Text style={styles.tableRowCol1}>Summa kapitalkostnader</Text>
              <Text style={styles.tableRowCol2}>
                {roundNumber(capitalCost?.total)}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.section}>
          <View style={styles.table}>
            <View style={styles.tableSumRow}>
              <Text style={styles.tableRowCol1}>Totala projektkostnader</Text>
              <Text style={styles.tableRowCol2}>
                {roundNumber(totalProjectExpenses)}
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionHeader}>Resultat</Text>

        <View style={styles.table}>
          {margins?.values.map((margin, index) => {
            return (
              <View style={styles.tableRow} key={index}>
                <Text style={styles.tableRowCol1}>
                  {formatText(margin.description)}
                </Text>
                <Text style={styles.tableRowCol2}>
                  {makePercent(margin.value)}
                </Text>
              </View>
            );
          })}
        </View>

        <View style={styles.section}>
          <View style={styles.table}>
            <View style={styles.tableSumRow}>
              <Text style={styles.tableRowCol1}>Totalt resultat</Text>
              <Text style={styles.tableRowCol2}>
                {roundNumber(totalResult)}
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionHeader}>Intäkter att slutredovisa</Text>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableRowCol1}>
              Intäkter att resultatavräkna
            </Text>
            <Text style={styles.tableRowCol2}>
              {roundNumber(summary?.revenue?.total)}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableRowCol1}>
              Avgår tidigare resultatavräknat
            </Text>
            <Text style={styles.tableRowCol2}>
              {roundNumber(summary?.revenue?.previouslyOffset)}
            </Text>
          </View>
          <View style={styles.tableSumRow}>
            <Text style={styles.tableRowCol1}>
              Periodens intäkter att resultatavräkna{" "}
            </Text>
            <Text style={styles.tableRowCol2}>
              {roundNumber(summary?.revenue?.value)}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.sectionHeader}>Kostnader att slutredovisa</Text>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableRowCol1}>
              Kostnader att resultatavräkna
            </Text>
            <Text style={styles.tableRowCol2}>
              {roundNumber(summary?.cost?.total)}
            </Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableRowCol1}>
              Avgår tidigare resultatavräknat
            </Text>
            <Text style={styles.tableRowCol2}>
              {roundNumber(summary?.cost?.previouslyOffset)}
            </Text>
          </View>
          <View style={styles.tableSumRow}>
            <Text style={styles.tableRowCol1}>
              Periodens kostnader att resultatavräkna
            </Text>
            <Text style={styles.tableRowCol2}>
              {roundNumber(summary?.cost?.value)}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <View style={styles.table}>
          <View style={styles.tableSumRow}>
            <Text style={styles.tableRowCol1}>
              Resultat att resultatavräkna vid slutredovisning
            </Text>
            <Text style={styles.tableRowCol2}>
              {roundNumber(summary?.result)}
            </Text>
          </View>
        </View>
      </View>
    </>
  );

  return PdfTemplate([page1], "Rapport slutredovisning");
};
